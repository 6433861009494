import React, { useEffect } from "react";
import Page from "../components/privacy-policy";

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Page />
    </div>
  );
}
